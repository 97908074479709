<template>
  <div class="row mt-5">

    <div class="col-md-10 mx-auto">

      <div class="folders-title d-flex align-items-center justify-content-between mb-3">
        <h5 class="mb-0">All Folders</h5>
        <div class="btn btn-green btn-sm"  @click="() => setShowingModal(true)">Create Folder</div>
      </div>

      <div v-if="getIsLoadingFolders">
        <div class="text-center">
          <spinner/>
        </div>
      </div>
      <div v-else>
        <p v-if="getAllFolders.length <= 0" class="mb-0">You don't have any folders!</p>
        <div v-else>
          <div v-for="folder in getAllFolders" :key="folder.id">
            <folders-table-row :folder="folder" />
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import Spinner from "@/components/Spinner";
import {mapActions, mapGetters} from "vuex";
import FoldersTableRow from "@/components/folders/folders-table-row";
export default {
  name: "FoldersAllTable",
  components: {FoldersTableRow, Spinner},

  computed: {
    ...mapGetters('folders', ['getAllFolders', 'getIsLoadingFolders']),

  },

  methods: {
    ...mapActions('folders', ['setShowingModal', 'fetchAllFolders']),
  },

  created() {
    this.fetchAllFolders();
  }

}
</script>

<style lang="scss" scoped>
.folder-card {
  background-color: white;
  border-radius: .5rem;
  box-shadow: 0 4px 25px 0 rgba(0,0,0,.1);
  -webkit-transition: all .3s ease-in-out;
  width: 100%;
  display: block;
  position: relative;
  margin-bottom: 15px;
  padding: 1.5rem 2.1rem;
}
</style>