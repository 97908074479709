<template>
  <div class="container">

    <folder-create-modal v-if="isShowingCreateFolderModal" />
    <folders-all-table/>

  </div>
</template>

<script>

import {mapActions, mapGetters} from "vuex";
import FolderCreateModal from "@/components/folders/folder-create-modal/FolderCreateModal";
import FoldersAllTable from "@/components/folders/folders-all-table/FoldersAllTable";

export default {
  name: "AllFolders",
  components: {FoldersAllTable, FolderCreateModal},
  computed: {
    ...mapGetters('folders', ['getFolderModalOpen']),

    isShowingCreateFolderModal() {
      return this.getFolderModalOpen;
    }

  },

  methods: {
    ...mapActions('folders', ['setShowingModal']),
  }

}
</script>
