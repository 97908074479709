<template>
  <div class="modal-container" id="customModal" @click="event => closeModal(event)">
    <div class="modal-content">

      <!-- Start of Modal title -->
      <div class="modal-header">
        <div class="d-flex justify-content-between align-items-center w-100">
          <h5>Create Folder</h5>
          <button aria-label="Close" class="btn-close" type="button" @click="hideModal"></button>
        </div>
      </div>
      <!-- End of Modal title -->

      <div class="container mt-3 mb-2">
        <div v-if="getIsLoadingFolders" class="text-center">
          <spinner />
        </div>
        <form v-else @submit.prevent="createNewFolder">
          <div class="mb-3">
            <label for="folderName">Folder Name</label>
            <input type="text" class="form-control" id="folderName" v-model="folderName" minlength="2" maxlength="165" required>
          </div>
          <div class="mb-3">
            <button type="submit" class="btn btn-green w-100">Create</button>
          </div>
        </form>
      </div>

    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import Spinner from "@/components/Spinner";

export default {
  name: "FolderCreateModal",
  components: {Spinner},
  data() {
    return {
      folderName: ''
    }
  },

  computed: {
    ...mapGetters('folders', ['getIsLoadingFolders'])
  },

  methods: {
    ...mapActions('folders', ['setShowingModal', 'addNewFolder']),

    closeModal(event) {
      if (event.target.id === 'customModal') {
        this.setShowingModal(false);
      }
    },

    hideModal() {
      this.setShowingModal(false);
    },

    createNewFolder() {
      this.addNewFolder(this.folderName);
    }

  }


}
</script>

<style lang="scss" scoped>
.modal-container {
  display: block;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  overflow: auto;

  background: rgba(0, 0, 0, 0.2);
  z-index: 9999;
}

.modal-content {
  position: relative;
  border: 1px solid rgba(0,0,0,.2);
  border-radius: .3rem;
  outline: 0;
  background: white;
  margin: 0 0;
  width: 100% !important;
  height: 100%;
  overflow: auto;

  @media (min-width: 991px) {
    margin: 3% auto; /* 15% from the top and centered */
    width: 80%;
    max-width: 900px;
    height: fit-content;
    overflow: revert;
  }

  /* Animate the content of the modal */
  -webkit-animation-name: animatetop;
  -webkit-animation-duration: 0.5s;
  animation-name: animatetop;
  animation-duration: 0.5s
}

.modal-header {
  position: relative;
  top: 0;
  right: 0;
  padding-bottom: 6px;
  border-bottom: 3px solid #4a3288;
  align-items: center;
}

@-webkit-keyframes animatetop {
  from {top:-10px; opacity:0}
  to {top:0; opacity:1}
}

@keyframes animatetop {
  from {top:-10px; opacity:0}
  to {top:0; opacity:1}
}
</style>
