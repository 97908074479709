<template>
  <div class="folders-row" v-on="!folder.isEdit ? { click: () => loadFolder() } : {}">
    <div class="d-flex justify-content-between align-items-center">
      <div>
        <h5 class="mb-1" v-if="!folder.isEdit">{{ folder.name }}</h5>
        <span v-if="!folder.isEdit">Created at {{ folderCreationDate }}</span>
        <input type="text" class="form-control" placeholder="Update Folder Name"
               @blur="restrictUrl($event)"
               @keyup.enter="restrictUrl($event)"
               v-model="folder.name" v-if="folder.isEdit" minlength="2" maxlength="165" >
        <edit-icon v-if="!folder.isEdit" class="custom-class mx-3"
                   @click="editFolderName($event,true)"></edit-icon>
        <button type="submit" class="mt-2" v-if="folder.isEdit"
                @click="editFolderName($event,false)">Update Folder Name  <check-icon ></check-icon></button>

      </div>
      <div>
        <folder-icon/>
      </div>
    </div>
  </div>
</template>

<script>
import {CheckIcon, EditIcon, FolderIcon} from "vue-feather-icons";
export default {
  name: "folders-table-row",

  props: {
    folder: Object
  },

  components: {
    FolderIcon, EditIcon, CheckIcon
  },

  computed: {

    folderCreationDate: function () {
      const convertedDate = new Date(this.folder.creation_date);

      /*// Old date
      const day = convertedDate.getUTCDay()
      const month = convertedDate.toLocaleString('default', {month: 'long', timeZone: 'UTC'});
      const year = convertedDate.getUTCFullYear();
      // return `${month}, ${day}, ${year}`;*/

      return convertedDate.toDateString();
    }

  },

  methods: {
    restrictUrl(e, flag) {
      e.stopPropagation()
    },
    editFolderName(e, flag) {
      e.stopPropagation()
      this.folder.isEdit = flag
      this.$store.dispatch('folders/update_folder_flag', this.folder);
      if(!flag){
        this.$store.dispatch('folders/updateFolderName', this.folder);
      }
    },
    loadFolder() {
      const folderId = this.folder.id;
      this.$router.push({name: 'Folder', params: {id: folderId}});
    }
  }

}
</script>

<style lang="scss" scoped>
/*.folders-row {
  width: 100%;
  padding: 1rem .4rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  transition: 0.25s;
  border-top: solid 1px #ececec;

  &:hover {
    background-color: #fafafa;
  }

}*/

.folders-row {
  background-color: white;
  border-radius: .5rem;
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, .1);
  -webkit-transition: all .3s ease-in-out;
  width: 100%;
  display: block;
  position: relative;
  margin-bottom: 20px;
  padding: 1rem 2.1rem;
  cursor: pointer;

  &:hover {
    background-color: #f6f6f6;
  }
  button {
    cursor: pointer;
    color: #666666;
    list-style: none;
    padding: 3px 1px;
    border: 1px solid #eee;
    border-radius: 6px;
    //box-shadow: 0 3px 3px 0 #ddd;
    font-size: 0.75rem;
    font-weight: bold;
    transition: all .2s;
    width: 100%;
    text-align: center;

    &:hover {
      color: #000000;
      box-shadow: 0 3px 3px 0 #cccccc;
    }
  }
}
</style>
